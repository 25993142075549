import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import toast from 'react-hot-toast';
import { Modal } from 'antd';
import { pointRedeemApi, siteSettings } from '../api';
import { ContractContext } from '../context/ContractContext';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import CheckableTag from 'antd/es/tag/CheckableTag';
import ConnectWallet from '../components/ConnectWallet';
import  ethers  from 'ethers';
import Footer from '../components/Footer';
const web3 = new Web3(window.ethereum);

const PointRedeem = () => {
    const [pointRedeem, setpointRedeem] = useState();
    const [pointData, setPointData] = useState("")
    const [isOpen, setIsOpen] = useState(false)
    const [placeholder, setPlaceholder] = useState("")
    const [points, setPoints] = useState({})
    const [nounce, setNounce] = useState(Date.now())
    const { contract, setFetchUser } = useContext(ContractContext);
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS
    const { user } = useSelector(state => state.user);
    const getRedeem = async (isRedeem = false) => {
        console.log(isRedeem);
        try {
            
            if (pointRedeem == 2) {
                const isAddress = isValidEthereumAddress(pointData)
                if (!isAddress) {
                    toast.error("Please Enter Valid Wallet Address")
                    return;
                }
            }
            
            if (!pointData) {
                if (!isRedeem) {
                    toast.error("Please Fill Details")
                    return;
                }
            }
            const { data } = await pointRedeemApi({ redeemPlan: pointRedeem, data: pointData })
            if (data.success) {
                toast.success(data.message)
                setIsOpen(false);
                setPointData("")
                setFetchUser(prev => prev + 1)

            } else {
                toast.error(data.message)
                setIsOpen(false);
                setPointData("")
            }
        } catch (err) {
            toast.error(err.response.data.message)
            setIsOpen(false);
            setPointData("")
        }
    }
    function isValidEthereumAddress(address) {
        // Check if the address starts with '0x' and is 42 characters long
        console.log(address);
        const re = /^(0x)?[0-9a-fA-F]{40}$/;
        return re.test(address);
    }
    // Create Public account 
    // useEffect(async () => {
    // const newaddress = web3.eth.accounts.create();;
    // console.log(newaddress);
    // const account = web3.eth.accounts.privateKeyToAccount("0x27d3f1bad4a197ea44fe4304cc6314fb1d3e0780b2fe3c86e5b0f8b453a3b9a9");
    // const publicAddress = account.address;
    // console.log('Public Address:', publicAddress);
    // console.log("0xAf484cD972267a7aD3Cf7b50d403ea6A95e65e9D");
    // }, [])



    useEffect(() => {
        switch (pointRedeem) {
            case 1:
                setPlaceholder("Enter your wallet address")
                break;
            case 2:
                setPlaceholder("Enter your wallet address")
                break;
            case 3:
                setPlaceholder("Enter your Discord Name")
                break;
            case 4:
                setPlaceholder("Enter your Discord Name")
                break;
        }
    }, [pointRedeem])
    const freemintContract = async () => {
        if (user.points < 50000) {
            toast.error("You have not enough points to mint")
            return;
        }
        try {
            const { signature, n } = getSignature();;
            const data1 = await contract.methods.walletOfOwner(user.walletAddress).call();
            console.log(data1);
            if (data1?.length) {
                toast.error("You have already pass of freemint")
                return;
            }
            const data = await contract.methods.freeMintPassCost().call();
            const suggestedGasPrice = await web3.eth.getGasPrice();
            await contract.methods.freeMintPass(n, signature)
            .send({ to: contractAddress, value: data.toString(), from: user.walletAddress,gasPrice:suggestedGasPrice })
            .once('error', (err) => {
                toast.error(err);
            })
            .then(r => {
                getRedeem(true)
            })
        } catch (err) { toast.error(err.message) }
    }
    const generateRandomUnit256String = () => {
        const web3 = new Web3();
        const randomHex = web3.utils.randomHex(32); // 32 bytes = 256 bits
        return randomHex;
    };
    const getSignature = () => {
        const n = generateRandomUnit256String();
        setNounce(n)
        const message = web3.utils.soliditySha3(user.walletAddress, n);
        const { signature } = web3.eth.accounts.sign(message, process.env.REACT_APP_UNIQUE_HASH);
        return { signature, n };
    }

    const getSettings = async () => {
        try {
            const {data} = await siteSettings();
            setPoints(data?.data)
            console.log(data);
        } catch (err) {}
    }
    useEffect(() => {
      getSettings();
    }, [user])
    
    return (
        <>
            <Navbar />
            <section className="mrg_t" style={{ minHeight: 400 }}>
                <div className="container">
                    {
                        user.walletAddress ?
                    <div className="row d-flex justify-content-evenly align-items-center mb-0 mb-md-5">
                        <div className="col-md-3 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center" style={{ cursor: "pointer" }} onClick={e => { setpointRedeem(1); freemintContract(); }}>
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Free mint pass </div>
                                </div>
                                <div className="p-3 text-center">
                                    <div className="redPoint">{points?.freemintPass}</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.2s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center" style={{ cursor: "pointer" }} onClick={e => { setpointRedeem(2); setIsOpen(true) }}>
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/White - Zk Realm Logo.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Whitelist</div>
                                </div>
                                <div className="p-3 text-center">
                                    <div className="redPoint">{points?.whitelist}</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.4s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center" style={{ cursor: "pointer" }} onClick={e => { setpointRedeem(3); setIsOpen(true) }}>
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/discord_white.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">Early Supporter</div>
                                </div>
                                <div className="p-3 text-center">
                                    <div className="redPoint">{points?.earlysupporter}</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 mb-4 mb-md-0 wow fadeInDown" data-wow-delay="1.6s">
                            <div className="redeemCardMain d-flex flex-column justify-content-center align-items-center" style={{ cursor: "pointer" }} onClick={e => { setpointRedeem(4); setIsOpen(true) }}>
                                <div className="redeemCard d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainPointRedCardIcon"><img src="/images/discord_white.png" className="img-fluid pointRedCardIcon" draggable="false" alt="Free mint redeem box" /></div>
                                    <div className="mt-3 fs-theme f-24 cl-t text-center">OG Supporter</div>
                                </div>
                                <div className="p-3 text-center" >
                                    <div className="redPoint">{points?.ogsupporter}</div>
                                    <div className="requires">(Requires points)</div>
                                </div>
                            </div>
                        </div>
                    </div> : <div className="d-flex h-100 flex-column justify-content-center align-items-center" style={{minHeight:550}}>
                                <h3 className='mb-5 text-center lh-base'>Connect your wallet to redeem your points and claim rewards!</h3>
                                <ConnectWallet /> 
                            </div>
                    }
                </div>
            </section>
            <Modal title="Redeem" open={isOpen} onOk={() => getRedeem()} onCancel={() => setIsOpen(false)}>
                <input placeholder={placeholder} className='cusInput w-100 my-2 text-white' onChange={e => setPointData(e.target.value)} value={pointData} />
            </Modal>
            <Footer></Footer>
        </>
    )
}

export default PointRedeem