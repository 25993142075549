import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import Web3 from 'web3';
import { getUser, registerUser } from '../api';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setContract, setUser } from '../store/Slices/userSlice';
import ContractAbi from '../abi/abi.json'
import { ContractContext } from '../context/ContractContext';
import ConnectWallet2 from './ConnectWallet2';

const ConnectWallet = () => {
    const [walletAddress, setWalletAddress] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.user)
    const { contract, setContract, fetchUser } = useContext(ContractContext);
    const web3 = new Web3(window.ethereum);
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', handleAccountsChanged);
            window.ethereum.on('chainChanged', () => window.location.reload());

            return () => {
                if (window.ethereum.removeListener) {
                    window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
                    window.ethereum.removeListener('chainChanged', () => window.location.reload());
                }
            };
        }
    }, []);

    const handleAccountsChanged = (accounts) => {
        if (accounts.length === 0) {
            setErrorMessage("Please connect to MetaMask.");
            setWalletAddress(null);
        } else {
            setWalletAddress(accounts[0]);
            setErrorMessage(null);
        }
    };
    const ContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                // Request account access if needed
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // Create a web3 instance
                
                const networkId = await web3.eth.net.getId()
                if (networkId == 324) {
                    // Get the wallet address
                    const accounts = await web3.eth.getAccounts();
                    const contract = new web3.eth.Contract(ContractAbi, ContractAddress)
                    console.log(contract)
                    setContract(contract)
                    const ref = searchParams.get('ref')
                    const { data } = await registerUser({ walletAddress: accounts[0], ref })
                    if (data.success) {
                        toast.success(data.message)
                        localStorage.setItem('token', data?.data?.token)
                        dispatch(setUser(data?.data))
                    }
                    setErrorMessage(null);
                } else {
                    addZkSyncNetwork();
                    localStorage.setItem('token', "")
                    dispatch(setUser({}))
                    // setErrorMessage("Please Connect With ZKSync Testnet")
                }
            } catch (error) {
                // console.log(error);
                // setErrorMessage("An error occurred: " + error.message);
            }
        } else {
            setErrorMessage("MetaMask is not installed. Please install it to use this feature.");
        }
    };

    const disConnectWallet = () => {
        
        setWalletAddress(null);
        setContract(null);
        localStorage.removeItem('token');
        dispatch(setUser({}));
        toast.success("Wallet disconnected successfully ✅");
    };
    const addZkSyncNetwork = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: "0x144",
                    chainName: 'zkSync Era Mainnet',
                    nativeCurrency: {
                        name: 'Ether',
                        symbol: 'ETH',
                        decimals: 18,
                    },
                    rpcUrls: ['https://mainnet.era.zksync.io'],
                    blockExplorerUrls: ['https://explorer.zksync.io'],
                }],
            });

            // Check if the network was added and selected
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });

            if (currentChainId === "0x144") {
                // console.log('zkSync Mainnet is active');
                // alert('zkSync Mainnet has been successfully added and is now active!');
            } else {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x144' }],
                });
            }
            connectWallet();
        } catch (error) {
            console.error("Error adding zkSync network:", error);
            alert('An error occurred while trying to add the zkSync Mainnet.');
        }
    };

    useEffect(() => {
        if (errorMessage) {
            toast.error(errorMessage)
            setTimeout(() => {
                setErrorMessage("")
            }, 3000);
        }
    }, [errorMessage])
    const defaultfunction = async () => {
        try {
            if (window.ethereum) {
                const web3_1 = new Web3(window.ethereum)
                const networkId = await web3_1.eth.net.getId()
                const contract = new web3_1.eth.Contract(ContractAbi, ContractAddress)
                setContract(contract)
                if (networkId == 324) {
                    const accounts = await web3_1.eth.getAccounts()
                    if (accounts[0]) {
                        const { data: user } = await getUser();
                        if (user.success) {
                            dispatch(setUser(user?.data))
                        }
                        setErrorMessage(null);
                    } else {
                        localStorage.setItem('token', "")
                        dispatch(setUser({}))
                    }
                }
            }
        } catch (err) { }
    }
    useEffect(() => {
        defaultfunction()
    }, [fetchUser]);

    return (
        <>
            {/* {
                user.walletAddress ?
                    <div className="cusBtn ms-3 ms-md-5" style={{ cursor: "pointer" }} onClick={disConnectWallet}>{user?.walletAddress?.slice(0, 5) + '...' + user?.walletAddress?.slice(-5)}</div>
                    : <div className="cusBtn ms-3 ms-md-5" style={{ cursor: "pointer" }} onClick={connectWallet}>{"CONNECT WALLET"}</div>
            } */}
            <ConnectWallet2></ConnectWallet2>
        </>
    );
};

export default ConnectWallet;
