import React from 'react'
import Navbar from '../components/Navbar'
import Spinner from '../components/spin/Spinner'
import ConnectWallet from '../components/ConnectWallet'
import { useSelector } from 'react-redux'
import Footer from '../components/Footer'

const Spin = () => {
  const { user } = useSelector(state => state.user)
  return (
    <>
      <Navbar></Navbar>
      {
        user.walletAddress ?
          <Spinner></Spinner> : <div className="d-flex h-100 flex-column justify-content-center align-items-center" style={{ minHeight: 550 }}>
            <h3 className='mb-5 text-center lh-base'>Connect your wallet to spin the wheel and win exciting rewards</h3>
            <ConnectWallet />
          </div>
      }
      <Footer></Footer>
    </>
  )
}

export default Spin