import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const BullBear = () => {
  return (
    <>
      <Navbar></Navbar>
      <section className="position-relative">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center py-5">
            <div className="col-lg-6 order-1 order-lg-0 mx-auto">
              <h1 className="fs-theme f-55 display-3 text-center wow fadeInDown text-uppercase" data-wow-delay="1.5s">Comming Soon</h1>
            </div>
            <div className="col-lg-6 order-0 order-lg-1 mx-auto mb-4 mb-lg-0 d-flex justify-content-center align-items-center wow flipInY" data-wow-delay="1s">
              <img src="/images/Main_Banner_Img.png" alt="Meta Beer Bottle Main Banner Image" draggable="false"
                className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  )
}

export default BullBear