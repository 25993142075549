import React from 'react'
import { useSelector } from 'react-redux'
import {  useNavigate } from 'react-router-dom'
const QuizTask = () => {
    const { user } = useSelector(state => state.user)
    const navigate = useNavigate();
    return (
        <>
            <div className="TaskCardBorder" style={{ opacity: user.isQuizCompleted ? 0.5 : 1, cursor: user.isQuizCompleted ? 'not-allowed' : 'pointer' }}>

                <div className="TaskCard fs-theme d-flex justify-content-between align-items-center" >
                    <div style={{ opacity: user.isQuizCompleted ? 0.5 : 1 }}>
                        <div className="TaskNo">{"Quiz Task"}</div>
                        <span className="cl-t">{"Complete the Zk Realm Quiz"}</span>
                    </div>
                    {/* <Link to={'/spin'}> */}
                    {
                        user.isQuizCompleted ?
                            <span className="TaskPoints" >Completed</span>
                            :
                            <span className="TaskPoints" onClick={() => navigate('/quiz')} >500 pts</span>
                    }
                    {/* </Link> */}
                </div>
            </div>
        </>
    )
}

export default QuizTask