import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

const Checkin = () => {
    const { user } = useSelector(state => state.user)
    const navigate = useNavigate();
    return (
        <>
            <div className="TaskCardBorder" style={{ opacity: user.isFirstCheckin ? 0.5 : 1, cursor: user.isFirstCheckin ? 'not-allowed' : 'pointer' }}>

                <div className="TaskCard fs-theme d-flex justify-content-between align-items-center" >
                    <div style={{ opacity: user.isFirstCheckin ? 0.5 : 1 }}>
                        <div className="TaskNo">{"Check In"}</div>
                        <span className="cl-t">{"Complete First Day CheckIn"}</span>
                    </div>
                        {
                            user.isFirstCheckin ?
                                <span className="TaskPoints" >Completed</span>
                                :
                                <span className="TaskPoints" onClick={() => navigate('/dailyCheckin')}>500 pts</span>
                        }
                </div>
            </div>
        </>
    )
}

export default Checkin