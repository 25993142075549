import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

const SpinTask = () => {
    const { user } = useSelector(state => state.user)
    const navigate = useNavigate();
    return (
        <>
            <div className="TaskCardBorder" style={{ opacity: user.totalSpin > 2 ? 0.5 : 1, cursor: user.totalSpin > 2 ? 'not-allowed' : 'pointer' }}>

                <div className="TaskCard fs-theme d-flex justify-content-between align-items-center" >
                    <div style={{ opacity: user.totalSpin > 2 ? 0.5 : 1 }}>
                        <div className="TaskNo">{"Spin"}</div>
                        <span className="cl-t">{"Complete 2 Spin"}</span>
                    </div>
                    {/* <Link to={'/spin'}> */}
                    {
                        user.totalSpin > 2 ?
                            <span className="TaskPoints" >Completed</span>
                            :
                            <span className="TaskPoints" onClick={() => navigate('/spin')} >500 pts</span>
                    }
                    {/* </Link> */}
                </div>
            </div>
        </>
    )
}

export default SpinTask