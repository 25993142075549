import React, { useContext, useEffect, useState } from 'react'
import { applyTask, completeTaskById, verifyTask } from '../../../api'
import toast from 'react-hot-toast'
import TaskModal from './TaskModal'
import { ContractContext } from '../../../context/ContractContext'

const TaskCard = ({ data, setTasks, setFetch }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { setFetchUser } = useContext(ContractContext);
    const addTask = async (id) => {
        try {
            // setIsOpen(true)
            applyTaskFunc();
        } catch (err) { }
    }

    const verifyTaskFunc = async (id) => {
        try {
            setIsOpen(true)
        } catch (err) { toast.error(err?.response?.data?.message || err.message) }
    }

    const applyTaskFunc = async () => {
        try {
            // setLoading(true)
            const { data:res } = await applyTask({ id: data?.task?._id, content: "Task Applied" })
            if (res?.success) {
                toast.success("Task Applied Successfully");
                setIsOpen(false)
                setFetch(prev => prev + 5)
                window.open(res.data.redirectURL, '_blank', 'noopener,noreferrer')
            }
        } catch (err) { toast.error(err?.response?.data?.message || err?.message) }
    }
    return (
        <>
            <div className="TaskCardBorder " style={{ opacity: data.status == "completed" ? 0.5 : 1, cursor: data.status == "completed" ? 'not-allowed' : 'pointer' }}>

                <div className="TaskCard fs-theme d-flex justify-content-between align-items-center" >
                    <div style={{ opacity: data.status == "completed" ? 0.5 : 1 }}>
                        <div className="TaskNo">{data?.task?.title}</div>
                        <span className="cl-t">{data?.task?.description}</span>
                    </div>
                    {
                        data.status == "pending" ?
                            <span className="TaskPoints" onClick={e => addTask(data?.task?._id)} >{data?.task?.points} pts</span>
                            : data.status == "applied" ?
                                <span className="TaskPoints" onClick={e => verifyTaskFunc(data?.task?._id)} >Verify Task</span> : <span aria-disabled className="TaskPoints">Completed</span>
                    }
                </div>
            </div>
            <TaskModal data={data} isOpen={isOpen} setIsOpen={setIsOpen} setFetch={setFetch} id={data?.task?._id}>

            </TaskModal>
        </>
    )
}

export default TaskCard