import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store'
import { Provider } from 'react-redux'
import { ConfigProvider, theme } from 'antd';
import { ContractProvider } from './context/ContractContext';
import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { WagmiProvider } from 'wagmi';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { config } from './wagmi';
const client = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
const customTheme = darkTheme({
  accentColor: "linear-gradient(90deg, #e000ea, #6000cc)", // Custom background color for the button
  accentColorForeground: "#FFFFFF", // Text color on the button
});
root.render(
  // <React.StrictMode>
  <WagmiProvider config={config}>
    <QueryClientProvider client={client}>
      <RainbowKitProvider theme={customTheme}>
        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm, token: { colorPrimary: "#e000ea" } }}>
          <Provider store={store}>
            <ContractProvider>
              <App />
            </ContractProvider>
          </Provider>
        </ConfigProvider>
      </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
