import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import api, { getUser, getUserPoints } from '../api';
import { setPoints, setUser } from '../store/Slices/userSlice';

const UpdateComponent = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const getPoints = async (url) => {
        try {
            // debugger
            const { data } = await getUser();
            if (data.success) {
                dispatch(setPoints(data.data.points))
            }
        } catch (err) { }
    }
    api.interceptors.response.use(
        response => {
            if (response.config.url !== "/user" && response.config.url !== "/register") {
                getPoints(response.config.url);
            }
            return response;
        },
        error => {
            return Promise.reject(error);
        }
    );
    return (
        <>
        </>
    )
}

export default UpdateComponent