import React, { useState } from 'react'

const AboutUs = () => {
    const [isReadMore, setIsReadMore] = useState(false)
    return (
        <section id="AboutUs">
            <div className="container mrg_b">
                <div className="row wow flash">
                    <div className="heading d-flex justify-content-center align-items-center mrg_tb">
                        <div className="right_line">
                            <div className="Line200 RGreen"></div>
                            <div className="Line100 ROrg ms-auto"></div>
                        </div>
                        <h2>ABOUT US</h2>
                        <div className="left_line">
                            <div className="Line200 LGreen"></div>
                            <div className="Line100 LOrg"></div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-lg-6 cusZindex wow slideInDown">
                        <img src="/images/aboutUsImg.png" alt="About Of ZK Realm" draggable="false" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 cusZindex wow fadeInLeft" data-wow-delay="1s">
                        <div className="cl-t lh-theme pb-3">
                            <h4 className="mb-3 cl-M">Welcome to ZK RealM</h4>
                            <p className='cl-t'>
                                A community-driven NFT project built on the zkSyncblockchain, ZK RealM seamlessly blends artistry with cutting-edge technology. With a carefully crafted supply of 5,555 NFTs, our mission is simple: to create authentic, meaningful experiences without overpromising—just pure creativity and innovation.
                            </p>
                            <p className='cl-t'>
                                At ZK RealM, we're redefining how NFTs connect with art, gamified rewards, and community engagement. Through free minting and exclusive whitelist opportunities, we’re inviting everyone to join us in shaping the future of this transformative space.
                            </p>
                        </div>
                        {
                            isReadMore &&
                            <div className="cl-t lh-theme pb-3">
                                <h4 className="mb-3 cl-M">Our Vision</h4>
                                <p className='cl-t'>
                                    ZK RealM was born from a desire to reimagine the NFT experience—moving beyond conventional boundaries to unlock new creative possibilities. Built on the secure and efficient zkSyncblockchain, our platform offers a seamless and accessible experience, empowering users to explore, collect, and engage with NFTs like never before.
                                </p>
                            </div>
                        }
                        {
                            !isReadMore ?
                                <div style={{ cursor: 'pointer' }} onClick={e => setIsReadMore(true)}><div className="cusBtnTwo mt-3">Read More</div></div> : ""
                        }
                    </div>
                </div>
                {isReadMore &&
                    <div style={{ position: "relative", zIndex: "99" }}>
                        <div className="cl-t lh-theme pb-3">
                            <h4 className="mb-3 cl-M">Why ZK RealM?</h4>
                            <p className='cl-t'>
                                At the heart of ZK RealM is our vibrant community. We believe in fostering a welcoming space where individuals from all walks of life can connect, share their passion for NFTs, and embark on exciting journeys together. To amplify user engagement and loyalty, we’ve introduced interactive features like tasks, referral rewards, gamified spins, and point-based redemptions—creating a dynamic and rewarding ecosystem for all.
                            </p>
                        </div>
                        <div className="cl-t lh-theme pb-3">
                            <h4 className="mb-3 cl-M">The Road Ahead</h4>
                            <p className='cl-t'>
                                Our journey is driven by innovation and a commitment to delivering value to our community. As we grow, we’ll continue to explore new ways to push the boundaries of creativity, offering unique NFT experiences that inspire and captivate
                            </p>
                            <p className='cl-t'>
                                Join us as we build a world where art, imagination, and rewards come together to create something extraordinary. This is just the beginning—Welcome to ZK REALM —where the adventure begins...
                            </p>
                        </div>
                    </div>
                }
            </div>
        </section >
    )
}

export default AboutUs