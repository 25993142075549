import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import toast from 'react-hot-toast'
import { getReferAndEarn } from '../api'
import { useSelector } from 'react-redux'
import clipboardCopy from 'clipboard-copy'
import ConnectWallet from '../components/ConnectWallet'
import { Modal } from 'antd'

const ReferAndEarn = () => {
    const [referAndEarn, setReferAndEarn] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const { user } = useSelector(state => state.user)
    useEffect(() => {
        (async () => {
            try {
                const { data: res } = await getReferAndEarn()
                setReferAndEarn(res?.data)
            } catch (error) {
                // toast.error(error.response.data.message)
            }
        })()
    }, [user])

    const copyToClipboard = () => {
        const generateLink = `${window.location.origin}/?ref=${user.walletAddress}`
        console.log(generateLink)
        clipboardCopy(generateLink)
        toast.success("Copied to clipboard")
    }

    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    {
                        user.walletAddress ?
                            <div className="row d-flex justify-content-center align-items-center">
                                <div className="col-md-10 col-lg-9 col-xl-8 py-4 wow flipInY" data-wow-delay="1s">
                                    <div className="referGlass">
                                        <div className="row p-4 p-md-4 p-lg-5  d-flex justify-content-center align-items-center">

                                            <div className="col-12 minusMrg">
                                                <img src="/images/ReferAndEarn_Image.png" className="img-fluid" alt="Refer And Earn Page Image" draggable="false" />
                                            </div>

                                            <div className="col-12 d-flex flex-column justify-content-around align-items-center pb-4">
                                                <div className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                                    <div className="pointHead text-center">Accumulated Referral Points :</div>
                                                    {/* <h1 className="pointData ms-lg-4 text-center">42000</h1> */}
                                                    <h1 className="pointData ms-lg-4 text-center">{referAndEarn.points}</h1>
                                                </div>
                                                <h6 onClick={e => setIsOpen(true)} className='text-decoration-underline cl-t' style={{cursor:"pointer"}}> How it works ?</h6>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="cusTable p-4 wow fadeInDown" data-wow-delay="1.6s">
                                                    <div className="LeadHead">People I've referred :</div>
                                                    <h1 className="LeadData mt-2 text-start">{referAndEarn.referralsCount}</h1>
                                                </div>
                                                <div className="cusTable p-4 my-4 wow fadeInDown" data-wow-delay="1.8s">
                                                    <div className="LeadHead">Successful people among the people I referred :</div>
                                                    <h1 className="LeadData mt-2 text-start">{referAndEarn.successReferrals}</h1>
                                                </div>

                                                <div className="referBtn d-flex justify-content-center align-iteams-center wow fadeInDown" data-wow-delay="2s">
                                                    <span className="my-auto RefText">
                                                        Refer a friend
                                                    </span>
                                                    <span className="ms-auto copyIcon" onClick={e => copyToClipboard()}>
                                                        <i className="fas fa-external-link cl-M"></i>
                                                    </span>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div> : <div className="d-flex h-100 flex-column justify-content-center align-items-center" style={{ minHeight: 550 }}>
                                <h3 className='mb-5 text-center lh-base'>Connect your wallet to start referring and earning rewards!</h3>
                                <ConnectWallet />
                            </div>
                    }
                </div>
            </section>
            <Modal footer="" title={""} width={700} open={isOpen} onClose={e => setIsOpen(false)} onCancel={e => setIsOpen(false)}>
                <div className="cl-t lh-theme">
                    <h3 className="mb-3 cl-M mt-2 fw-bold">How It Works</h3>
                    <p className=' h6 lh-lg'>
                        🎯 Share Your Unique Link – Spread the word! Share your referral link on social media, with friends, or through messaging apps.
                    </p>
                    <p className=' h6 lh-lg pt-2'>
                        🧑‍🤝‍🧑 Invite & Earn – Once your referred friend earns 10k points, your referral will be counted as successful, and both of you will earn 5,000 points as a reward!
                    </p>
                </div>
            </Modal>
            <Footer />
        </>
    )
}

export default ReferAndEarn