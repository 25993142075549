import { ConnectButton } from '@rainbow-me/rainbowkit'
import React, { useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { registerUser } from '../api';
import toast from 'react-hot-toast';
import { setUser } from '../store/Slices/userSlice';
import { useDispatch } from 'react-redux';
import { ethers } from "ethers";
import ContractAbi from '../abi/abi.json'
import Web3 from 'web3';
import { ContractContext } from '../context/ContractContext';
const web3 = new Web3(window.ethereum);
const ConnectWallet2 = () => {
    const { address, isConnected, chainId, isConnecting, isDisconnected, status } = useAccount();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const ContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const { contract, setContract, fetchUser } = useContext(ContractContext);

    const connectWallet = async () => {
        const ref = searchParams.get('ref')
        const { data } = await registerUser({ walletAddress: address, ref })
        if (data.success) {
            // toast.success(data.message)
            localStorage.setItem('token', data?.data?.token)
            console.log("token Saved")
            const contract = new web3.eth.Contract(ContractAbi, ContractAddress)
            console.log(contract);
            dispatch(setUser(data?.data))
            setContract(contract)
        }
    }
    useEffect(() => {
        if (isConnected) {
            connectWallet();
        } else {
            localStorage.removeItem('token');
            dispatch(setUser({}));
        }
    }, [isConnected, isConnecting, status])

    useEffect(() => {
        // console.log(isDisconnected)
        console.log(status)
    }, [status])


    return (
        <>
            <ConnectButton showBalance={false} chainStatus={'none'} accountStatus={'full'} />
        </>
    )
}

export default ConnectWallet2