import React from 'react'

const Roadmap = () => {
    return (
        <section id="ROADMAP" className="position-relative pad_t">

            <img src="/images/Right-Spider-Net.png" alt="Horror Spider Net imafge for decorate ZK Realm website" className="img-fluid RightSpiderNet" draggable="false" />
            <img src="/images/Left-Spider-Net.png" alt="Horror Spider Net imafge for decorate ZK Realm website" className="img-fluid LeftSpiderNet" draggable="false" />

            <div className="container">

                {/* <!-- ============  Heading  ============ --> */}
                <div className="row wow flash mrg_b">
                    <div className="heading d-flex justify-content-center align-items-center">
                        <div className="right_line">
                            <div className="Line200 RGreen"></div>
                            <div className="Line100 ROrg ms-auto"></div>
                        </div>
                        <h2>THE VISION AHEAD</h2>
                        <div className="left_line">
                            <div className="Line200 LGreen"></div>
                            <div className="Line100 LOrg"></div>
                        </div>
                    </div>
                </div>

                {/* <!-- ============  Heading  ============ --> */}
                <div className="row">

                    <div className="timeline">
                        <ul>
                            <li className="wow fadeInLeft">
                                <div className="content">
                                    <h5 className="cl-M">Initial Setup and Platform Development</h5>
                                    <p className="f-14">
                                        {`-> Establish the project infrastructure on the zkSyncblockchain.`}<br />
                                        {`-> Develop core platform features, including seamless user authentication and account management.`}
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>1</h4>
                                </div>
                            </li>

                            <li className="wow fadeInRight">
                                <div className="content">
                                    <h5 className="cl-V">Community Kickoff</h5>
                                    <p className="f-14">
                                        {`-> Launch official social media channels (Discord, Twitter, etc.).`}<br />
                                        {`-> Host engaging campaigns to invite users to join Discord and follow on Twitter.`}<br />
                                        {`-> Organize community-building activities to spark meaningful connections.`}
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>2</h4>
                                </div>
                            </li>

                            <li className="wow fadeInLeft">
                                <div className="content">
                                    <h5 className="cl-M">Gamified Innovation</h5>
                                    <p className="f-14">
                                        {`-> Introducing engaging features like daily rewards, challenges, and exclusive games (e.g., Bull and Bear).`}<br />
                                        {`-> Highlighting achievements through leaderboards, boosting community pride`}<br />
                                        {/* {`-> Integrate Refer & Earn`}<br />
                                {`-> Integrate Bull and Bear GAME`}<br />
                                {`-> Integrate Leaderboard`}<br />
                                {`-> Integrate spin feature allowing users to earn rewards like free mint, Lucky Box , WL , 2x points, and 5x points and much more`}<br /> */}
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>3</h4>
                                </div>
                            </li>

                            <li className="wow fadeInRight">
                                <div className="content">
                                    <h5 className="cl-V">Referral Ecosystem</h5>
                                    <p className="f-14">
                                        {`-> Build a robust referral program with personalized referral links.`}<br />
                                        {`-> Reward users with points for every successful referral, tracked seamlessly on the platform.`}
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>4</h4>
                                </div>
                            </li>

                            <li className="wow fadeInLeft">
                                <div className="content">
                                    <h5 className="cl-M">Rewards Redemption Hub</h5>
                                    <p className="f-14">
                                        {`-> Launch the Point Redemption System, allowing users to redeem earned points for:`}<br />
                                        {`-> Whitelist access`}<br />
                                        {`-> Exclusive Discord roles`}<br />
                                        {`-> Free mint opportunities and other stuff`}<br />
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>5</h4>
                                </div>
                            </li>

                            <li className="wow fadeInRight">
                                <div className="content">
                                    <h5 className="cl-V">The Rise of ZRUNDEADS</h5>
                                    <p className="f-14">
                                        {`-> Launching the highly anticipated ZRUNDEADS collection, setting a new benchmark for NFTs on zkSync.`}<br />
                                        {`-> Celebrating the release with giveaways, events, and exclusive drops for early supporters.`}
                                        <br />
                                        {`-> Monitor platform performance and user engagement metrics, making adjustments as necessary.`}
                                    </p>
                                </div>
                                <div className="time">
                                    <h4>6</h4>
                                </div>
                            </li>

                            <div style={{ clear: 'both' }}></div>
                        </ul>
                    </div>
                </div>

            </div>
            <div className="circleAnimation4"><div className="MainHvr"><div className="UnderMainHvr"><div className="circle circlethree"></div></div></div></div>
        </section>
    )
}

export default Roadmap