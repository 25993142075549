import React from 'react'

const HeroSection = () => {
    return (
        <section className="position-relative">
            <div className="container">
                <div className="row d-flex justify-content-center align-items-center py-5">
                    <div className="col-lg-6 order-1 order-lg-0 mx-auto">
                        <div className="cl-M wow fadeInLeft" data-wow-delay="1.3s">Discover the ZK Realm:</div>
                        <h1 className="fs-theme f-55 text-start wow fadeInDown" data-wow-delay="1.5s">The Perfect Fusion of Art and Gamified Rewards via NFTs...</h1>
                    </div>
                    <div className="col-lg-6 order-0 order-lg-1 mx-auto mb-4 mb-lg-0 d-flex justify-content-center align-items-center wow flipInY" data-wow-delay="1s">
                        <img src="/images/Main_Banner_Img.png" alt="Meta Beer Bottle Main Banner Image" draggable="false"
                            className="img-fluid" />
                    </div>
                </div>
                <div className="row">
                    <a href="#AboutUs" className="dis-contents">
                        <div className="scrollCircle mx-auto position-relative">
                            <img src="/images/downArrow.png" alt="Scroll Bottom Icon" draggable="false" className="img-fluid" />
                        </div>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default HeroSection