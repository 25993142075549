import axios from 'axios';

const api = axios.create({
    baseURL: "https://api.zkrealm.io/api",
    // baseURL: "http://localhost:5000/api",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem('token')
    },
})
api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token && token != 'null') {
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const getallmenus = () => api.get('/getallmenu')

export const registerUser = (data) => api.post('/connect', data)
export const getReferAndEarn = () => api.get('/referrals')

export const getLeaderBoard = (page) => api.get(`/leaderboard?page=${page}`)
export const getRank = () => api.get('/getrank')
export const getUserPoints = () => api.get('/getpoints')
export const getUser = () => api.get('/user')

export const getAllTasks = () => api.get('/tasks')
export const applyTask = (data) => api.post('/applyTask', data)
export const verifyTask = (data) => api.post('/verifyTask', data)
export const getQuestions = () => api.get('/getQuestions');
export const completeQuiz = () => api.get('/completeQuiz');

export const completeTaskById = (id) => api.get(`/completetask/${id}`)

export const spin = (data) => api.post('/spin', data);
export const remainSpins = () => api.get('/remainSpin')

export const pointRedeemApi = (data) => api.post('/pointredeem', data)

export const getDailyCheckin = () => api.get('/checkin')
export const claimReward = () => api.get('/ClaimCheckIn')

export const siteSettings = ()  => api.get('/getsiteSetting')


export default api;
